$(function() {

    var App = (function(){

        return {
            init : function() {
                $(".selectpicker").selectpicker({});

                $.stellar({
                    horizontalScrolling: false
                });
                
                $(window).on('scroll',function() {
                   var point = $(window).scrollTop();
                    if (point < 200) {
                        $('.btn-to-top').fadeOut();
                    } else {
                        $('.btn-to-top').fadeIn();
                    }

                });
                $('.btn-to-top').on('click',function(e){
                    e.preventDefault();

                    var body = $("html, body");
                    body.stop().animate({scrollTop:0}, '500', 'swing');
                });

                $('[href="#order"]').on('click', function(e) {
                    e.preventDefault();
                    var point = $('#order').offset().top - 20,
                        body = $("html, body");

                    if (point) {
                        body.stop().animate({scrollTop: point}, '500', 'swing');
                    }
                });

                $('[data-form-validate]').each(function(i, form) {
                    $(form).validate({
                        errorClass:'has-error',
                        errorPlacement: function(){
                            return false;
                        }
                    });
                });

                var options = {
                    beforeSubmit: function () {
                        return $("#order").validate();
                    },
                    success: function() {
                        $('#error, #order').hide();
                        $('#success').show();
                    },
                    error: function() {
                        $('#error').show();
                    }
                };
				
                $('#order').ajaxForm(options);
            }
        }
    })()

    /**
     * Dummy Module Example
     */
    ,DummyModule = (function(){
        return {
            init : function() {
                // do something
            }
        }
    })()

    ;App.init();

});
